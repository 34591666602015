import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './auth.guard'; 

const newLocal = 'pro_plans';
export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'  },
  { path: '404', component: P404Component, data: { title: 'Page 404' } },
  { path: '500', component: P500Component, data: { title: 'Page 500' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login Page' } },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard], 
    data: { title: 'Dashboard' },
    children: [
      { path: 'dashboard', loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule), data: { title: 'Dashboard' } },
      { path: 'feedback', loadChildren: () => import('./views/feedback/feedback.module').then(f => f.FeedbackModule), data: { title: 'Feedback' } },
      { path: 'reports', loadChildren: () => import('./views/reports/reports.module').then(r => r.ReportsModule), data: { title: 'All Reports' } },
      { path: newLocal, loadChildren: () => import('./views/pro-plans/pro-plan.module').then(pp => pp.ProPlansModule ), data: { title: 'Pro Plans' } },
      { path: 'all_users', loadChildren: () => import('./views/users/users.module').then(u => u.UsersModule), data: { title: 'All Users' } },
      { path: 'userprofile/:id', loadChildren: () => import('./views/userprofile/userprofile.module').then(up => up.UserprofileModule), data: { title: 'User Profile' } },
      { path: 'waiting_approval', loadChildren: () => import('./views/waiting_approval/waiting_approval.module').then(w => w.WaitingApprovalModule), data: { title: 'Waiting Approval' } },
      { path: 'userdetails/:id', loadChildren: () => import('./views/userdetails/userdetails.module').then(ud => ud.UserdetailsModule), data: { title: 'User Details' } },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
