import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Users'
  },
  {
    name: 'All Users',
    url: '/all_users',
    icon: 'fa fa-group'
  },
  {
    name: 'Waiting Approval',
    url: '/waiting_approval',
    icon: 'fa fa-clock-o'
  },
  {
    title: true,
    name: 'Reports'
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'fa fa-tasks',
  },
  {
    title: true,
    name: 'Notifications'
  },
  {
    name: 'Feedback',
    url: '/feedback',
    icon: 'fa fa-inbox',
  },
  {
    title: true,
    name: 'Plans'
  },
  {
    name: 'Pro Plans',
    url: '/pro_plans',
    icon: 'fa fa-gift',
  }
];
