import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {  
  public get loggedIn(): boolean {  
    return (localStorage.getItem('AdminData') !== null);  
  }   
} 
/*
export class AuthServiceService {

  constructor() { }
}
*/
