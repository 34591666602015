import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from "@angular/forms";
import { environment } from '../../../environments/environment';
import { Router } from "@angular/router";
import { AuthServiceService } from "../../auth-service.service";
import Swal from 'sweetalert2';

const admin_url = environment.admin_url;
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})

export class LoginComponent implements OnInit { 
  form: FormGroup;
  email:string = '';
  password:string = '';
  

  constructor( public fb: FormBuilder, private http:HttpClient, private router: Router, private AuthService: AuthServiceService ){ 
    this.form = this.fb.group({
      email: [''],
      password: ['']
    })
  } 
  ngOnInit(): void{
    if((localStorage.getItem('AdminData') !== null)){
      this.router.navigate(['/dashboard']);
    }
  }
  checkLogin(){
    console.log(this.email);
    console.log(this.password);
    var formData: any = new FormData();
    formData.append("email", this.form.get('email').value);
    formData.append("password", this.form.get('password').value);
    this.http.post( admin_url+'adminLogin.php', formData ).subscribe((result) =>{
      if (result['status'] == 1) {
        let storageData1 = {
          API_KEY: result['API_KEY'],
          Session_ID: result['Session_ID'],
          ad_email_id: result['ad_email_id'],
          ad_id: result['ad_id'],
          ad_name: result['ad_name'],
        };
        let storageData = JSON.stringify(storageData1);
        localStorage.setItem('AdminData', storageData);
        this.router.navigate(['/dashboard']);
      } else {
        Swal.fire(result['message'], '', 'error');
      }
    })
  }
}

